
import { useStore } from 'vuex'
import { defineComponent, computed, reactive, watch, onMounted, toRefs } from 'vue'
import { URLS } from '@/common/js/constant/index'
import Cookie from 'js-cookie'
import { get } from '@/plugins'
import { LoadingOutlined } from '@ant-design/icons-vue'
import KlookIcon from '@/components/icon.vue'

export default defineComponent({
  components: {
    LoadingOutlined,
    KlookIcon
  },
  props: {
    value: String,
    disabled: Boolean,
    set: Boolean,
    size: {
      type: String,
      default: 'default'
    }
  },
  setup(props, ctx) {
    const store = useStore()
    const state: {
      phoneCode: any
      phoneNumber: any
      isSelectFocus: boolean
      allCountryCode: any
      codeList: any
    } = reactive({
      phoneCode: '',
      phoneNumber: '',
      isSelectFocus: false,
      allCountryCode: [],
      codeList: []
    })
    onMounted(() => {
      getPhoneCountryCode()
      if (props.set) {
        const index: any = props.value && props.value.indexOf('-')
        if (index > -1 && props.value) {
          state.phoneCode = props.value.slice(0, index) || defaultCode()
          state.phoneNumber = props.value.slice(index + 1)
        }
      }
    })
    watch(
      () => state.phoneNumber,
      (val) => {
        state.phoneNumber = val.replace(/\D/g, '')
      }
    )
    watch(
      () => props.value,
      (val: any) => {
        const index: any = val && val.indexOf('-')
        if (index > -1) {
          state.phoneCode = val.slice(0, index) || defaultCode()
          state.phoneNumber = val.slice(index + 1)
          return
        } else {
          state.phoneNumber = val
        }
      }
    )

    watch(
      () => state.phoneCode,
      () => {
        triggerUpdate()
      }
    )
    watch(
      () => state.phoneNumber,
      () => {
        triggerUpdate()
      }
    )

    const defaultCode = () => Cookie.get('localIdd') || '886'
    /**
     * 筛选电话code
     * @param {String} input 输入筛选的值
     */
    const filterList = (value: string) => {
      if (value) {
        state.codeList = state.allCountryCode.filter((item: any) => {
          return item.country.toLowerCase().indexOf(value.toLowerCase()) >= 0
        })
      } else {
        state.codeList = state.allCountryCode
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      document!.querySelector('.klook-phone-code-list')!.scrollTop = 0
    }

    // 电话输入过滤非数组
    const filterNumber = () => {
      state.phoneNumber = state.phoneNumber.replace(/\D/gi, '')
    }

    /**
     * 国家被选中
     * @param {Object} info 被选中的项
     */
    const onCodeSelected = (info: any) => {
      state.phoneCode = state.codeList[info].code
    }

    // 触发更新
    const triggerUpdate = () => {
      const phoneNumber = state.phoneNumber && state.phoneNumber.trim()
      const phoneCode = state.phoneCode
      let value = ''

      if (phoneNumber && phoneCode) {
        value = `${state.phoneCode}-${phoneNumber}`
      } else if (phoneNumber) {
        value = `-${phoneNumber}`
      } else if (phoneCode) {
        value = `${state.phoneCode}-`
      }

      ctx.emit('update:value', value)
    }

    // 触发change事件
    const triggerChange = () => {
      triggerUpdate()
      ctx.emit('change')
    }

    // 触发change事件
    const triggerBlur = () => {
      triggerUpdate()
      ctx.emit('blur')
    }

    // 获取国家电话code
    const getPhoneCountryCode = () => {
      // 存一个获取电话区号中的状态到vuex，避免重复请求
      const isLoadingPhoneCode = store.state.countryPhone.isLoadingPhoneCode
      const countryCode = store.state.countryPhone.phoneCountryCode

      if (!countryCode.length && !isLoadingPhoneCode) {
        store.commit('countryPhone/setLoadingPhoneCode', true)

        get(URLS.getPhoneCode).then((res: any) => {
          store.commit('countryPhone/setPhoneCountryCode', res)
          state.allCountryCode = res
          state.codeList = state.allCountryCode
        })
      } else {
        state.allCountryCode = countryCode
        state.codeList = state.allCountryCode
      }
    }
    const blurFunction = () => {
      state.isSelectFocus = false
      triggerBlur()
    }
    const onMouseDown = (e: any) => {
      e.preventDefault()
      return false
    }
    return {
      ...toRefs(state),
      defaultCode: computed(() => defaultCode()),
      filterList,
      filterNumber,
      onCodeSelected,
      triggerUpdate,
      triggerChange,
      triggerBlur,
      blurFunction,
      onMouseDown
    }
  }
})
