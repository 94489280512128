import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createSlots as _createSlots, withKeys as _withKeys, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ae327fb4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "klook-phone-code-input" }
const _hoisted_2 = { onMouseDown: "onMouseDown" }
const _hoisted_3 = { style: {"width":"100% !important"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_klook_icon = _resolveComponent("klook-icon")!
  const _component_loading_outlined = _resolveComponent("loading-outlined")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_input = _resolveComponent("a-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_select, {
        value: _ctx.phoneCode ? '+' + _ctx.phoneCode : '+' + _ctx.defaultCode,
        disabled: _ctx.disabled,
        size: _ctx.size,
        dropdownClassName: "klook-phone-code-list",
        showSearch: "",
        onSearch: _ctx.filterList,
        onChange: _ctx.triggerChange,
        onFocus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isSelectFocus = true)),
        onBlur: _ctx.blurFunction,
        optionFilterProp: "value",
        dropdownMatchSelectWidth: false
      }, _createSlots({
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.codeList, (item, index) => {
            return (_openBlock(), _createBlock(_component_a_select_option, {
              onClick: 
            () => {
              _ctx.onCodeSelected(index)
            }
          ,
              key: index,
              value: item.code,
              label: item.country
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, _toDisplayString(item.country), 1)
              ]),
              _: 2
            }, 1032, ["onClick", "value", "label"]))
          }), 128))
        ]),
        _: 2
      }, [
        (_ctx.allCountryCode.length)
          ? {
              name: "suffixIcon",
              fn: _withCtx(() => [
                _createVNode(_component_klook_icon, { type: "down" })
              ])
            }
          : {
              name: "suffixIcon",
              fn: _withCtx(() => [
                _createVNode(_component_loading_outlined)
              ])
            }
      ]), 1032, ["value", "disabled", "size", "onSearch", "onChange", "onBlur"])
    ]),
    _createVNode(_component_a_input, {
      value: _ctx.phoneNumber,
      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.phoneNumber) = $event)),
      size: _ctx.size,
      disabled: _ctx.disabled,
      placeholder: _ctx.$t('75603'),
      onKeyup: _withKeys(_ctx.filterNumber, ["enter"]),
      onChange: _ctx.triggerChange,
      onBlur: _ctx.triggerBlur,
      type: "tel"
    }, {
      suffix: _withCtx(() => [
        _createVNode(_component_klook_icon, { type: "mobile" })
      ]),
      _: 1
    }, 8, ["value", "size", "disabled", "placeholder", "onKeyup", "onChange", "onBlur"])
  ]))
}